import { render, staticRenderFns } from "./DappDetailedTokensTable.vue?vue&type=template&id=62d2a805&scoped=true"
import script from "./DappDetailedTokensTable.vue?vue&type=script&lang=ts"
export * from "./DappDetailedTokensTable.vue?vue&type=script&lang=ts"
import style0 from "./DappDetailedTokensTable.vue?vue&type=style&index=0&id=62d2a805&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62d2a805",
  null
  
)

export default component.exports