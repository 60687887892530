












import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({})
export default class TruncateCellRender extends MixinScreenSize {
  @Prop() fullValue?: number
  @Prop() symbol?: string
}
