




















































import {Component, Prop} from 'vue-property-decorator'
import {Dapp} from '@/model/resource/Dapp'
import TokensPriceTable from '@/components/tokensprice/TokensPriceTable.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import PoweredBy, {PoweredByItem} from '@/components/PoweredBy.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {PoweredBy, LastPriceUpdated, TokensPriceTable},
})
export default class DappDetailedTokensTable extends MixinScreenSize {
  @Prop({type: Dapp, required: true}) dapp!: Dapp

  collection = new TokensTableItemsCollection()

  poweredByList = [
    {
      url: 'https://flamingo.finance/',
      alt: 'flamingo icon',
      filename: 'flamingo-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://www.forthewin.network/#/',
      alt: 'ftwicon',
      filename: 'for-the-win-logo.svg',
    } as PoweredByItem,
    {
      url: 'https://neofura-1.gitbook.io/api-docs/',
      alt: 'magnet icon',
      filename: 'magnet-icon.svg',
    } as PoweredByItem,
  ]

  lastTimeUpdated = ''

  get hasTokens() {
    return (
      this.collection.items.length > 0 && this.collection.items[0].$id !== 0
    )
  }

  async mounted() {
    this.applyResources()
    await this.populateResources()
  }

  applyResources() {
    this.collection.withoutTotal = true
    this.collection.perPage = this.collection.total
    this.collection.orderBy = 'marketCap'
    this.collection.minOrder = 0
    this.collection.asc = false
    this.collection.active = true
    this.collection.slugDapp = this.dapp.slug
  }

  async populateResources() {
    await this.$await.run('populateTokensTable', () =>
      this.collection.queryAsPage()
    )
  }

  handleChangeLastTimeUpdated(lastTimeUpdated: string) {
    this.lastTimeUpdated = lastTimeUpdated
  }
}
