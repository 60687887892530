


































































import {Component, Prop, Vue} from 'vue-property-decorator'
import NavbarMenu from '@/components/NavbarMenu.vue'
import AppFooter from '@/components/AppFooter.vue'
import {NeoFeed} from '@/app/NeoFeed'
import DappDetailedStats from '@/components/dappDetailed/DappDetailedStats.vue'
import DappDetailedFeed from '@/components/dappDetailed/DappDetailedFeed.vue'
import DappDetailedTokensTable from '@/components/dappDetailed/DappDetailedTokensTable.vue'
import DappDetailedSummary from '@/components/dappDetailed/DappDetailedSummary.vue'
import {Dapp} from '@/model/resource/Dapp'
import {DappInfo} from '@/model/resource/DappInfo'
import {ContractCollection} from '@/model/collection/ContractCollection'

@Component({
  components: {
    DappDetailedStats,
    DappDetailedFeed,
    DappDetailedTokensTable,
    DappDetailedSummary,
    NavbarMenu,
    AppFooter,
  },
})
export default class DappDetailed extends Vue {
  @Prop({type: Boolean, required: true}) isDapp!: boolean

  dapp = new Dapp()
  contractCollection = new ContractCollection()
  dappInfo = new DappInfo()
  feed = new NeoFeed()

  initialHash = this.$route.params.hash ?? null

  async created() {
    await this.populateResources()
  }

  async populateResources() {
    if (!this.$route.params.id) {
      await this.$router.push('/')
      return
    }

    try {
      this.$await.run('populateDapp', () =>
        this.dapp.getDapp(this.$route.params.id)
      )
    } catch {
      await this.$router.push('/')
    }

    this.dappInfo.getInfoDapp(this.$route.params.id)

    this.feed.populate().catch(() => {
      this.feed = new NeoFeed()
    })
  }
}
